import {BaseFileInputValue} from '../components/inputs/FileInput/BaseFileInputValue'
import {FileInputValueCollection} from '../components/inputs/FileInput/FileInputValueCollection'

export class ApiFormData<T extends Record<string, any>> {
  constructor(private payload: T) {}

  public getFormData = () => {
    const formData = new FormData()

    Object.entries(this.payload).forEach(([key, value]) => {
      if (value instanceof FileInputValueCollection) {
        const files = (value as FileInputValueCollection<BaseFileInputValue>).getFileArray()
        files.forEach((file) => {
          const blob = file.toApiValue()
          if (blob) {
            formData.append(key, blob)
          }
        })
      } else if (Array.isArray(value) && key === 'customFields') {
        formData.append(key, JSON.stringify(value))
      } else if (Array.isArray(value)) {
        value.forEach((value) => formData.append(`${key}[]`, value))
      } else {
        if (typeof value !== 'undefined') formData.append(key, value)
      }
    })

    return formData
  }
}
