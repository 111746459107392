import {MerchandiseOrderModel} from './../../../../models/merchandise/MerchandiseOrderModel'
import axios from 'axios'
import {FilterModel} from '../../../../models/FilterModel'
import {
  MerchandiseOutletModel,
  MerchandiseOutletModelCreateFormDataParams,
} from '../../../../models/merchandise/MerchandiseOutletModel'

import {
  MerchandiseProductCategoryModel,
  MerchandiseProductCategoryModelCreateFormDataParams,
} from '../../../../models/merchandise/MerchandiseProductCategoryModel'

import {
  MerchandiseProductModel,
  MerchandiseProductModelCreateFormDataParams,
} from '../../../../models/merchandise/MerchandiseProductModel'

import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {MerchandiseWidgetModel} from '../../../../models/merchandise/MerchandiseWidgetModel'
import {DateUtil} from '../../../../utils/DateUtil'
import {
  MerchandiseUserModel,
  MerchandiseUserModelCreateParams,
} from '../../../../models/merchandise/MerchandiseUserModel'
import {MerchandiseLogsModel} from '../../../../models/merchandise/MerchandiseLogsModel'

const CRUD_OUTLET = '/merchandise-outlet'
const CRUD_PRODUCT = '/merchandise-product'
const CRUD_PRODUCT_CATEGORY = '/merchandise-product-category'
const CRUD_ORDER = '/merchandise-order'
const CRUD_USER = '/merchandise-user'

// OUTLET
export const GetOutlets = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<MerchandiseOutletModel>>(`${CRUD_OUTLET}/list`, filters)

export const GetOutletByCode = (code: string) =>
  axios.get<MerchandiseOutletModel>(`${CRUD_OUTLET}/${code}`)
export const DeleteOutlets = (codes: string[]) =>
  axios.delete<unknown>(`${CRUD_OUTLET}`, {data: {codes}})
export const PostOutlet = (data: MerchandiseOutletModelCreateFormDataParams) =>
  axios.post<unknown>(`${CRUD_OUTLET}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
export const PutOutlet = (outletCode: string, data: MerchandiseOutletModelCreateFormDataParams) =>
  axios.put<unknown>(`${CRUD_OUTLET}/${outletCode}`, data.getFormData())

export const DownloadOutletReports = (outletCode: string, date: Date) =>
  axios.post<Blob>(
    `${CRUD_OUTLET}/report`,
    {
      outletCode,
      date: DateUtil.convertDateToApiString(date),
    },
    {
      responseType: 'blob',
    }
  )

// PRODUCT
export const GetProducts = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<MerchandiseProductModel>>(`${CRUD_PRODUCT}/list`, filters)
export const GetProductByCode = (code: string) =>
  axios.get<MerchandiseProductModel>(`${CRUD_PRODUCT}/${code}`)
export const DeleteProducts = (codes: string[]) =>
  axios.delete<unknown>(`${CRUD_PRODUCT}`, {data: {codes}})
export const PostProduct = (data: MerchandiseProductModelCreateFormDataParams) =>
  axios.post<unknown>(`${CRUD_PRODUCT}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
export const PutProduct = (code: string, data: MerchandiseProductModelCreateFormDataParams) =>
  axios.put<unknown>(`${CRUD_PRODUCT}/${code}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

// PRODUCT CATEGORY
export const GetProductCategories = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<MerchandiseProductCategoryModel>>(
    `${CRUD_PRODUCT_CATEGORY}/list`,
    filters
  )
export const GetProductCategoryByCode = (code: string) =>
  axios.get<MerchandiseProductCategoryModel>(`${CRUD_PRODUCT_CATEGORY}/${code}`)

export const DeleteProductCategories = (codes: string[]) =>
  axios.delete<unknown>(`${CRUD_PRODUCT_CATEGORY}`, {data: {codes}})

export const PostProductCategory = (data: MerchandiseProductCategoryModelCreateFormDataParams) =>
  axios.post<unknown>(`${CRUD_PRODUCT_CATEGORY}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const PutProductCategory = (
  code: string,
  data: MerchandiseProductCategoryModelCreateFormDataParams
) =>
  axios.put<unknown>(`${CRUD_PRODUCT_CATEGORY}/${code}`, data.getFormData(), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

// ORDERS

export const GetOrders = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<MerchandiseOrderModel>>(`${CRUD_ORDER}/list`, filters)

// WIDGET
interface WidgetModelDataParams {
  widgets: string[]
  eventCode: string
  date: string
  outletCode?: string
}
export const GetWidgetData = ({widgets, eventCode, date, outletCode}: WidgetModelDataParams) =>
  axios.post<MerchandiseWidgetModel>('/widget/merchandise', {
    event: eventCode,
    outlet: outletCode,
    date,
    widgets,
  })

// USER

export const GetUserByCode = (code: string) =>
  axios.get<MerchandiseUserModel>(`${CRUD_USER}/${code}`)
export const GetUsers = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<MerchandiseUserModel>>(`${CRUD_USER}/list`, filters)
export const DeleteUsers = (codes: string[]) =>
  axios.delete<unknown>(`${CRUD_USER}`, {data: {codes}})
export const PostUser = (data: MerchandiseUserModelCreateParams) =>
  axios.post<unknown>(`${CRUD_USER}`, data)
export const PutUser = (code: string, data: MerchandiseUserModelCreateParams) =>
  axios.put<unknown>(`${CRUD_USER}/${code}`, data)
export const ResetUserCredentials = (outletCode: string, code: string) =>
  axios.post<unknown>(`${CRUD_USER}/reset`, {outletCode, code})

// LOGS

export const GetOutletLogs = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<MerchandiseLogsModel>>(`/log/merchandise`, filters)
