import axios, {AxiosError, AxiosResponse} from 'axios'
import {ActivityModel, ActivityModelCreateParams} from '../../../../models/ems/ActivityModel'
import {
  BookingModel,
  BookingModelAddress,
  BookingModelCreateParams,
} from '../../../../models/ems/BookingModel'
import {BundleModel, BundleModelCreateParams} from '../../../../models/ems/BundleModel'
import {EventModel, EventModelType} from '../../../../models/ems/EventModel'
import {FilterModel} from '../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {
  ProductCategoryModel,
  ProductCategoryModelCreateParams,
} from '../../../../models/ems/ProductCategoryModel'
import {
  BookingProductListModel,
  ProductModel,
  ProductModelCreateParams,
} from '../../../../models/ems/ProductModel'
import {TicketLogModel} from '../../../../models/acs/TicketLogModel'
import {
  TicketListModel,
  TicketModel,
  TicketModelChangeSeatParams,
  TicketModelFulfillParams,
  TicketModelUpdateParams,
} from '../../../../models/ems/TicketModel'
import {
  PollQuestionModel,
  PollQuestionModelCreateParams,
  PollQuestionModelTranslationCreateParams,
} from '../../../../models/eva/PollQuestionModel'
import {WidgetModel} from '../../../../models/ems/WidgetModel'
import {
  ReservationItemModel,
  ReservationModel,
  ReservationModelCreateParams,
} from '../../../../models/ems/ReservationModel'
import {CustomerModel} from '../../../../models/CustomerModel'
import {AramexShipmentStatusModel} from '../../../../models/AramexShipmentStatusModel'
import {
  WorkshopGuestModel,
  WorkshopModel,
  WorkshopModelCreateParams,
  WorkshopModelTranslationCreateParams,
  WorkshopSpeakerModel,
} from '../../../../models/ems/WorkshopModel'
import {GuestModel} from '../../../../models/GuestModel'
import {
  NotificationModel,
  NotificationModelCreateParams,
} from '../../../../models/ems/NotificationModel'

import {PokenCreateParams, PokenModel} from '../../../../models/acs/PokenModel'
import {BadgeModel, BadgeModelCreateParams} from '../../../../models/BadgeModel'

const CRUD_BOOKING = '/booking'
const CRUD_EVENT = '/event'
const CRUD_ACTIVITY = '/event/activity'
const CRUD_PRODUCT = '/product'
const CRUD_BUNDLE = '/bundle'
const CRUD_RESERVATION = '/reservation'
const CRUD_PRODUCT_CATEGORY = '/product-category'
const CRUD_TICKET = '/ticket'
const EMS_SEARCH_BOOKING = '/booking/list'
const EMS_SEARCH_BOOKING_PRODUCT = '/booking/product'
const EMS_SEARCH_BOOKING_BUNDLE = '/booking/bundles'
const EMS_SEARCH_EVENT = '/event/list'
const EMS_SEARCH_ACTIVITY = '/event/activity/list'
const EMS_SEARCH_BUNDLE = `${CRUD_BUNDLE}/list`
const EMS_SEARCH_PRODUCT = '/product/list'
const EMS_SEARCH_PRODUCT_TREE = '/product/tree'
const EMS_SEARCH_PRODUCT_CATEGORY = '/product-category/list'

const DeleteBookings = (codes: string[]) => axios.delete(CRUD_BOOKING, {data: {codes}})
const CancelBooking = (bookingCodes: string[], reason: string) =>
  axios.post<unknown>(`${CRUD_BOOKING}/cancel`, {reason, codes: bookingCodes})
const PrintBookingCard = (bookingCodes: string[]) =>
  axios.post<Blob>(
    `/ticket/booking/print-card`,
    {codes: bookingCodes},
    {
      responseType: 'blob',
    }
  )
const ShipBooking = (bookingCode: string) =>
  axios.post<Blob>(
    `/booking/ship`,
    {code: bookingCode},
    {
      responseType: 'blob',
    }
  )
const SendBooking = (bookingCodes: string[]) =>
  axios.post<unknown>(`/ticket/booking/email-ticket`, {codes: bookingCodes})
const DeleteEvents = (codes: string[]) => axios.delete(CRUD_EVENT, {data: {codes}})
const DeleteProducts = (codes: string[]) => axios.delete(CRUD_PRODUCT, {data: {codes}})
const DeleteBundles = (codes: string[]) => axios.delete(CRUD_BUNDLE, {data: {codes}})
const DeleteProductCategories = (codes: string[]) =>
  axios.delete(CRUD_PRODUCT_CATEGORY, {data: {codes}})
const DeleteActivities = (codes: string[]) => axios.delete(CRUD_ACTIVITY, {data: {codes}})

const ReleaseReservation = (reservationCodes: string[], reason: string) =>
  axios.post<unknown>(`${CRUD_RESERVATION}/release`, {reason, codes: reservationCodes})

const GetBookingAirwayBill = (airwayBill: string) =>
  axios.get<Blob>(`/file/${airwayBill}`, {responseType: 'blob'})
const GetBookings = (filter?: FilterModel) => axios.post(EMS_SEARCH_BOOKING, filter)
const BookingSendPrint = (bookingCode: string) =>
  axios.get<Blob>(`/ticket/booking/${bookingCode}/send-print`, {
    responseType: 'blob',
  })
const GetBookingByCode = (code: string) => axios.get<BookingModel>(`${CRUD_BOOKING}/${code}`)
const GetEventsTree = () => axios.get<EventModel[]>(`${CRUD_EVENT}/tree`)
const GetEventParents = () => axios.get<EventModel[]>(`${CRUD_EVENT}/parent`)
const GetBookingProducts = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductModel>>(`${EMS_SEARCH_BOOKING_PRODUCT}`, filter)
const GetBookingDetails = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<BookingProductListModel>>(`/booking/detail`, filter)
const GetBookingBundles = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<BundleModel>>(`${EMS_SEARCH_BOOKING_BUNDLE}`, filter)
const GetEvents = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<EventModel>>(EMS_SEARCH_EVENT, filter)
const GetEventTypes = () => axios.get<EventModelType[]>(`/event/type`)
const GetEventsAndActivities = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<EventModel>>(`${EMS_SEARCH_EVENT}/all`, filter)
const GetActivities = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ActivityModel>>(EMS_SEARCH_ACTIVITY, filter)
const GetBundles = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<BundleModel>>(EMS_SEARCH_BUNDLE, filter)
const GetBundleByCode = (code: string) => axios.get<BundleModel>(`${CRUD_BUNDLE}/${code}`)
const GetEventByCode = (code: string) => axios.get<EventModel>(`${CRUD_EVENT}/${code}`)
const GetDefaultEvent = (): Promise<AxiosResponse<EventModel | null>> =>
  axios.get<EventModel>(`${CRUD_EVENT}/default`).catch((e: AxiosError<EventModel>) => {
    if (e.response?.status === 400) {
      const response: AxiosResponse<EventModel | null> = e.response
      response.data = null
      return response
    }
    throw e
  })
const GetEventProducts = (eventCode: string) =>
  axios.post<{products: ProductModel[]; bundles: BundleModel[]}>(
    `${CRUD_EVENT}/${eventCode}/product`
  )

const GetEventNonSeatedProducts = (eventCode: string, filter?: FilterModel) =>
  axios.post<{products: ProductModel[]}>(`${CRUD_EVENT}/${eventCode}${CRUD_PRODUCT}`, filter)
const GetActivityNonSeatedProducts = (eventCode: string, filter?: FilterModel) =>
  axios.post<{products: ProductModel[]}>(`${CRUD_ACTIVITY}/${eventCode}${CRUD_PRODUCT}`, filter)

const SetEventAsDefault = (code: string) => axios.post(`${CRUD_EVENT}/${code}`)

const GetActivityByCode = (code: string) => axios.get<ActivityModel>(`${CRUD_ACTIVITY}/${code}`)
const GetProducts = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductModel>>(EMS_SEARCH_PRODUCT, filter)
const GetProductTree = (filter?: FilterModel) =>
  axios.post<ProductModel[]>(EMS_SEARCH_PRODUCT_TREE, filter)
const GetProductByCode = (code: string) => axios.get(`${CRUD_PRODUCT}/${code}`)
const GetProductCategories = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ProductModel>>(EMS_SEARCH_PRODUCT_CATEGORY, filter)
const GetProductCategoryTree = (filter?: FilterModel) =>
  axios.post<ProductCategoryModel[]>(`${CRUD_PRODUCT_CATEGORY}/tree`, filter)
const GetParentProductsByProductCategory = (productCategoryCode: string) =>
  axios.get<ProductModel[]>(`${CRUD_PRODUCT_CATEGORY}/${productCategoryCode}/product`)
const GetProductCategoryByCode = (code: string) =>
  axios.get<ProductCategoryModel>(`${CRUD_PRODUCT_CATEGORY}/${code}`)
const GetReservations = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ReservationModel>>(`reservation/list`, filter)
const GetReservationByCode = (code: string) => axios.get(`/reservation/${code}`)
const GetReservationItems = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<ReservationItemModel>>(`reservation/details`, filter)

const PostBooking = (data: BookingModelCreateParams) => axios.post(CRUD_BOOKING, data)
const PostEvent = (data: FormData) => axios.post<EventModel>(CRUD_EVENT, data)
const PostActivity = (data: ActivityModelCreateParams) =>
  axios.post<ActivityModel>(CRUD_ACTIVITY, data)
const PostProduct = (data: ProductModelCreateParams) => axios.post(`${CRUD_PRODUCT}`, data)
const PostBundle = (data: BundleModelCreateParams) => axios.post(`${CRUD_BUNDLE}`, data)
const PostProductCategory = (data: ProductCategoryModelCreateParams) =>
  axios.post(`${CRUD_PRODUCT_CATEGORY}`, data)
const PostReservation = (data: ReservationModelCreateParams) => axios.post(CRUD_RESERVATION, data)

const PutBooking = (data: BookingModelCreateParams, code: string) =>
  axios.put(`${CRUD_BOOKING}/${code}`, data)
const PutEvent = (data: FormData, code: string) =>
  axios.put<EventModel>(`${CRUD_EVENT}/${code}`, data)
const PutActivity = (data: ActivityModelCreateParams, code: string) =>
  axios.put<ActivityModel>(`${CRUD_ACTIVITY}/${code}`, data)
const PutProduct = (data: ProductModelCreateParams, code: string) =>
  axios.put(`${CRUD_PRODUCT}/${code}`, data)
const PutBundle = (data: BundleModelCreateParams, code: string) =>
  axios.put(`${CRUD_BUNDLE}/${code}`, data)
const PutProductCategory = (data: ProductCategoryModelCreateParams, code: string) =>
  axios.put(`${CRUD_PRODUCT_CATEGORY}/${code}`, data)

const GetTickets = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<TicketListModel>>(`${CRUD_TICKET}/list`, filter)
const GetTicketByCode = (ticketCode: string) =>
  axios.get<TicketModel>(`${CRUD_TICKET}/${ticketCode}`)
const FulfillTicket = (data: TicketModelFulfillParams) =>
  axios.post<GlobalSearchModel<TicketModel>>(`${CRUD_TICKET}/fulfill`, data)
const FulfillNonSeated = (bookingProductCode: string) =>
  axios.post<GlobalSearchModel<TicketModel>>(`${CRUD_TICKET}/fulfill/non-seated`, {
    productCode: bookingProductCode,
  })
const FulfillVoucher = (bookingProductCode: string) =>
  axios.post(`/voucher/fulfill`, {bookingProductCode})
const PutTicket = (data: TicketModelUpdateParams, code: string) =>
  axios.put<GlobalSearchModel<TicketModel>>(`${CRUD_TICKET}/${code}`, data)
const CancelTickets = (ticketCodes: string[], reason: string) =>
  axios.post<unknown>(`${CRUD_TICKET}/cancel`, {
    codes: ticketCodes,
    reason,
  })
const ChangeTicketSeat = (ticketCode: string, payload: TicketModelChangeSeatParams) =>
  axios.put<Blob>(`${CRUD_TICKET}/${ticketCode}/change-seat`, payload, {
    responseType: 'blob',
  })
const ReprintTickets = (ticketCodes: string[], reason: string) =>
  axios.post<Blob>(
    `/template/reprint/tickets`,
    {
      codes: ticketCodes,
      reason,
    },
    {
      responseType: 'blob',
    }
  )
const PrintTickets = (ticketCodes: string[]) =>
  axios.post<Blob>(
    `/template/print/tickets`,
    {
      codes: ticketCodes,
    },
    {
      responseType: 'blob',
    }
  )
const ActivateTicket = (code: string, rfid: string) =>
  axios.post<unknown>(`${CRUD_TICKET}/activate-card`, {
    code,
    rfid,
  })
const DownloadTicketQrCode = (ticketCode: string) =>
  axios.get<Blob>(`${CRUD_TICKET}/${ticketCode}/print-label`, {
    responseType: 'blob',
  })
const GetTicketLogs = (filter?: FilterModel) =>
  axios.post<GlobalSearchModel<TicketLogModel>>(`/log/ticket`, filter)

const GetPollQuestionList = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<PollQuestionModel>>('/poll-question/list', filters)
const GetPollQuestionByCode = (questionCode: string) =>
  axios.get<PollQuestionModel>(`/poll-question/${questionCode}`)
const CreatePollQuestion = (params: PollQuestionModelCreateParams) =>
  axios.post(`/poll-question`, params)
const UpdatePollQuestion = (questionCode: string, params: PollQuestionModelCreateParams) =>
  axios.put(`/poll-question/${questionCode}`, params)
const DeletePollQuestions = (questionCodes: string[]) =>
  axios.delete(`/poll-question`, {
    data: {
      codes: questionCodes,
    },
  })
const GetPollQuestionTranslation = (code: string, lang: string) =>
  axios.get<PollQuestionModel>(`/poll-question/${code}/${lang}`)
const CreateOrUpdatePollQuestionTranslations = (
  code: string,
  params: PollQuestionModelTranslationCreateParams
) => axios.post<PollQuestionModel>(`/poll-question/translation/${code}`, params)
const GetWidgets = async ({date, eventCode, widgetCodes, month, year}: GetWidgetsParam) =>
  axios.post<WidgetModel>(`/widget`, {
    event: eventCode,
    widgets: widgetCodes,
    date,
    month,
    year,
  })

const TriggerBadgeCollectionScanner = (eventCode: string, gateCode: string, isStart: boolean) =>
  axios.post(`/badge-collection`, {
    eventCode,
    gateCode,
    isStart,
  })
const ScanBadgeCollection = (eventCode: string, qrCode: string) =>
  axios.post<CustomerModel>(`/badge-collection/customer`, {
    customerCode: qrCode,
    eventCode,
  })
const ClaimBadge = (data: FormData) => axios.post(`/badge-collection/claim`, data)
const AssignGuestSlot = (guestCode: string, slot: string) =>
  axios.post(`/guest/assign-slot/${guestCode}`, {slot})
const SendGuestCredentials = (codes: string[]) => axios.post(`/guest/send-credential`, {codes})

const AddConfigGroupToEvent = (
  eventCode: string,
  configGroupCode: string,
  isActive: boolean = false
) =>
  axios.post(`/event/config-group/${eventCode}`, {
    configGroupCode,
    isActive: isActive,
  })
const RemoveConfigGroupFromEvent = (eventCode: string, configGroupCode: string) =>
  axios.delete(`/event/config-group/${eventCode}`, {
    data: {
      configGroupCode,
    },
  })
const CheckEventHasActiveConfigGroupType = async (eventCode: string, configTypeCode: string) => {
  const {data} = await axios.post<{count: number}>(
    `/event/config-group/active/count/${eventCode}`,
    {
      typeCode: configTypeCode,
    }
  )
  return data.count > 0
}
const SyncRoyalGuard = (eventCode: string, tokens: string[]) =>
  axios.post(`/guest/royal-guard/refresh`, {
    eventCode,
    codes: tokens,
  })
const GetAirwayBillData = (awb: string) =>
  axios.get<AramexShipmentStatusModel[]>(`/booking/track/${awb}`)
const CreateOrUpdateBookingAddress = (bookingCode: string, address: BookingModelAddress) =>
  axios.post(`/booking/address/${bookingCode}`, address)

const SearchWorkshops = (filters: FilterModel) =>
  axios.post<GlobalSearchModel<WorkshopModel>>(`/workshop/list`, filters)
const GetWorkshopByCode = (code: string) => axios.get<WorkshopModel>(`/workshop/${code}`)
const DeleteWorkshops = (codes: string[]) =>
  axios.delete<WorkshopModel>(`/workshop`, {
    data: {
      codes,
    },
  })
const UpdateWorkshop = async (code: string, params: WorkshopModelCreateParams) => {
  const formData = getWorkshopFormData(params)
  return axios.put(`/workshop/${code}`, formData)
}
const CreateWorkshop = async (params: WorkshopModelCreateParams) => {
  const formData = getWorkshopFormData(params)
  return axios.post(`/workshop`, formData)
}
const CreateOrUpdateWorkshopTranslations = (
  partnerCode: string,
  params: WorkshopModelTranslationCreateParams
) => axios.post<WorkshopModel>(`/workshop/translation/${partnerCode}`, params)
const GetWorkshopTranslation = (code: string, lang: string) =>
  axios.get<WorkshopModel>(`/workshop/${code}/${lang}`)

const SearchWorkshopGuests = (workshopCode: string, filters?: FilterModel) =>
  axios.post<GlobalSearchModel<WorkshopGuestModel>>(`/workshop/guest/${workshopCode}`, filters)
const AddWorkshopGuest = (workshopCode: string, guestCodes: string[]) =>
  axios.post<{succeeded: GuestModel[]; failed: GuestModel[]}>(
    `/workshop/add-guest/${workshopCode}`,
    {
      guestCodes,
    }
  )
const RemoveWorkshopGuest = (workshopCode: string, guestCodes: string[]) =>
  axios.post(`/workshop/remove-guest/${workshopCode}`, {
    guestCodes,
  })
const ApproveWorkshopGuest = (workshopCode: string, guestCodes: string[]) =>
  axios.post(`/workshop/approve-guest/${workshopCode}`, {
    guestCodes,
  })
const SearchWorkshopSpeakers = (workshopCode: string, filters?: FilterModel) =>
  axios.post<GlobalSearchModel<WorkshopSpeakerModel>>(`/workshop/speaker/${workshopCode}`, filters)
const AddWorkshopSpeaker = (workshopCode: string, speakerCodes: string[]) =>
  axios.post(`/workshop/add-speaker/${workshopCode}`, {
    speakerCodes,
  })
const RemoveWorkshopSpeaker = (workshopCode: string, speakerCodes: string[]) =>
  axios.post(`/workshop/remove-speaker/${workshopCode}`, {
    speakerCodes,
  })

const SearchNotifications = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<NotificationModel>>(`/ems-notification/list`, filters)
const GetNotificationByCode = (code: string) =>
  axios.get<NotificationModel>(`/ems-notification/${code}`)
const DeleteNotificationByCodes = (codes: string[]) =>
  axios.delete<NotificationModel>(`/ems-notification`, {
    data: {
      codes,
    },
  })
const UpdateNotification = async (code: string, params: NotificationModelCreateParams) =>
  axios.put(`/ems-notification/${code}`, params)
const CreateNotification = async (params: NotificationModelCreateParams) =>
  axios.post(`/ems-notification`, params)
const TriggerNotifications = async (codes: string[]) =>
  axios.post(`/ems-notification/trigger`, {codes})

const SearchPoken = (filters: FilterModel) =>
  axios.post<GlobalSearchModel<PokenModel>>(`/poken/list`, filters)
const GetPokenByCode = (code: string) => axios.get<PokenModel>(`/poken/${code}`)
const DeletePokens = (codes: string[]) =>
  axios.delete<PokenModel>(`/poken`, {
    data: {
      codes,
    },
  })
const UpdatePoken = async (code: string, params: PokenCreateParams) => {
  const formData = getPokenFormData(params)
  return axios.put(`/poken/${code}`, formData)
}
const CreatePoken = async (params: PokenCreateParams) => {
  const formData = getPokenFormData(params)
  return axios.post(`/poken`, formData)
}

const CreateOrUpdatePokenTranslations = (
  partnerCode: string,
  params: WorkshopModelTranslationCreateParams
) => axios.post<PokenModel>(`/poken/translation/${partnerCode}`, params)

const GetPokenTranslation = (code: string, lang: string) =>
  axios.get<PokenModel>(`/poken/${code}/${lang}`)

interface GetWidgetsParam {
  widgetCodes: (keyof WidgetModel)[]
  eventCode: string
  date: string
  month?: number
  year?: number
}

const getWorkshopFormData = (params: WorkshopModelCreateParams) => {
  const formData = new FormData()

  formData.append('name', params.name)
  formData.append('eventCode', params.eventCode)
  formData.append('locationCode', params.locationCode)
  formData.append('startedAt', params.startedAt)
  formData.append('endedAt', params.endedAt)
  formData.append('isWaitList', String(params.isWaitList))
  formData.append('isAutoApproved', String(params.isAutoApproved))
  formData.append('hasMaxLimit', String(params.hasMaxLimit))
  formData.append('hasProductLimit', String(params.hasProductLimit))
  formData.append('isFeatured', String(params.isFeatured))
  params.customFields && formData.append('customFields', JSON.stringify(params.customFields))
  params.gateCode && formData.append('gateCode', params.gateCode)
  params.description && formData.append('description', params.description)
  params.slug && formData.append('slug', params.slug)
  params.instructions && formData.append('instructions', params.instructions)

  typeof params.maxLimit === 'number' && formData.append('maxLimit', String(params.maxLimit))
  typeof params.allowOtherProducts === 'boolean' &&
    formData.append('allowOtherProducts', String(params.allowOtherProducts))

  params.productsLimit?.forEach((productLimit, i) => {
    formData.append(`productLimits[${i}][productCode]`, productLimit.productCode)
    formData.append(`productLimits[${i}][maxLimit]`, String(productLimit.maxLimit))
  })
  params.emsFiles?.forEach((file) => {
    formData.append(`emsFiles`, file)
  })
  params.deletedFileCodes?.forEach((code) => {
    formData.append(`deletedFileCodes[]`, code)
  })
  if (params.translations) {
    Object.entries(params.translations).forEach(([lang, translation]) => {
      formData.append(`translations[${lang}]`, JSON.stringify(translation))
    })
  }
  return formData
}

const getPokenFormData = (params: PokenCreateParams) => {
  const formData = new FormData()

  formData.append('name', params.name)
  formData.append('eventCode', params.eventCode)
  params.isPublished && formData.append('isPublished', params.isPublished.toString())
  params.gateCode && formData.append('gateCode', params.gateCode)
  params.description && formData.append('description', params.description)
  params.alias && formData.append('alias', params.alias)
  params.instructions && formData.append('instructions', params.instructions)
  params.pokenEmsFiles?.forEach((file) => {
    formData.append(`pokenEmsFiles`, file)
  })
  params.pokenPdfs?.forEach((file) => {
    formData.append(`pokenPdfs`, file)
  })
  params.pokenLogo && formData.append(`pokenLogo`, params.pokenLogo)
  params.deletedFileCodes?.forEach((code) => {
    formData.append(`deletedFileCodes[]`, code)
  })

  return formData
}

const GetBadgeByCode = (code: string) => axios.get<BadgeModel>(`/badge/${code}`)
const SearchBadges = (filters?: FilterModel) =>
  axios.post<GlobalSearchModel<BadgeModel>>('/badge/list', filters)
const CreateBadge = (data: BadgeModelCreateParams) => axios.post(`/badge`, data)
const CreateBadgeByTemplate = (templateCode: string, eventCode: string, copies: number) =>
  axios.post(`/badge`, {
    eventCode,
    templateCode,
    copies,
  })
const UpdateBadge = (code: string, data: BadgeModelCreateParams) =>
  axios.put(`/badge/${code}`, data)
const PreactivateBadge = (eventCode: string, badgeCode: string, rfid: string) =>
  axios.post(`/badge/pre-activate`, {
    eventCode,
    code: badgeCode,
    rfid,
  })
const ActivateBadges = (eventCode: string, badgeCodes: string[]) =>
  axios.post(`/badge/activate`, {
    eventCode,
    codes: badgeCodes,
  })
const CancelBadges = (eventCode: string, badgeCodes: string[], reason: string) =>
  axios.post(`/badge/cancel`, {
    eventCode,
    codes: badgeCodes,
    reason,
  })
const PrintBadges = (eventCode: string, badgeCodes: string[]) =>
  axios.post<Blob>(
    `/badge/print`,
    {
      eventCode,
      codes: badgeCodes,
    },
    {responseType: 'blob'}
  )
const ReprintBadges = (eventCode: string, badgeCodes: string[], reason: string) =>
  axios.post<Blob>(
    `/badge/re-print`,
    {
      eventCode,
      codes: badgeCodes,
      reason,
    },
    {responseType: 'blob'}
  )

export {
  ShipBooking,
  DeleteBookings,
  DeleteEvents,
  DeleteProducts,
  DeleteProductCategories,
  DeleteBundles,
  SendBooking,
  PrintBookingCard as PrintBooking,
  GetBookings,
  GetBookingByCode,
  GetBookingProducts,
  GetBookingDetails,
  GetBookingBundles,
  GetBundles,
  GetBundleByCode,
  GetEventsAndActivities,
  GetEvents,
  GetEventsTree,
  GetEventParents,
  GetEventProducts,
  GetEventNonSeatedProducts,
  GetActivityNonSeatedProducts,
  GetEventByCode,
  GetEventTypes,
  GetDefaultEvent,
  SetEventAsDefault,
  GetProducts,
  GetProductTree,
  GetProductByCode,
  GetProductCategoryTree,
  GetProductCategories,
  GetProductCategoryByCode,
  GetParentProductsByProductCategory,
  GetReservations,
  GetReservationByCode,
  GetReservationItems,
  PostBooking,
  PostEvent,
  PostProduct,
  PostProductCategory,
  PostBundle,
  PostReservation,
  PutBooking,
  PutEvent,
  PutProduct,
  PutProductCategory,
  PutBundle,
  GetActivities,
  GetActivityByCode,
  PostActivity,
  PutActivity,
  DeleteActivities,
  GetTickets,
  GetTicketByCode,
  FulfillTicket,
  ActivateTicket,
  DownloadTicketQrCode,
  ChangeTicketSeat,
  PutTicket,
  CancelTickets,
  ReprintTickets,
  PrintTickets,
  BookingSendPrint,
  FulfillNonSeated,
  FulfillVoucher,
  GetTicketLogs,
  CancelBooking,
  GetBookingAirwayBill,
  GetPollQuestionByCode,
  GetPollQuestionList,
  CreatePollQuestion,
  UpdatePollQuestion,
  DeletePollQuestions,
  GetPollQuestionTranslation,
  CreateOrUpdatePollQuestionTranslations,
  GetWidgets,
  ReleaseReservation,
  TriggerBadgeCollectionScanner,
  ClaimBadge,
  AssignGuestSlot,
  AddConfigGroupToEvent,
  RemoveConfigGroupFromEvent,
  CheckEventHasActiveConfigGroupType,
  SyncRoyalGuard,
  ScanBadgeCollection,
  GetAirwayBillData,
  CreateOrUpdateBookingAddress,
  SearchWorkshops,
  GetWorkshopByCode,
  DeleteWorkshops,
  UpdateWorkshop,
  CreateWorkshop,
  SearchWorkshopGuests,
  AddWorkshopGuest,
  RemoveWorkshopGuest,
  SearchWorkshopSpeakers,
  AddWorkshopSpeaker,
  RemoveWorkshopSpeaker,
  SendGuestCredentials,
  CreateOrUpdateWorkshopTranslations,
  GetWorkshopTranslation,
  ApproveWorkshopGuest,
  SearchNotifications,
  TriggerNotifications,
  GetNotificationByCode,
  DeleteNotificationByCodes,
  UpdateNotification,
  CreateNotification,
  SearchPoken,
  CreatePoken,
  GetPokenByCode,
  UpdatePoken,
  DeletePokens,
  CreateOrUpdatePokenTranslations,
  GetPokenTranslation,
  GetBadgeByCode,
  SearchBadges,
  CreateBadge,
  UpdateBadge,
  CreateBadgeByTemplate,
  PreactivateBadge,
  ActivateBadges,
  CancelBadges,
  PrintBadges,
  ReprintBadges,
}
